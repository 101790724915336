<template>
  <div
    class="xl:w-340 text-center flex flex-col justify-center  px-4 xl:px-8"
  >
    <div
      class="w-full z-20 xl:max-w-sm text-center mx-auto xl:relative"
    >
      <h2
        class="inline-block text-gray-800 leading-tight relative px-2 z-20 bg-tertiary-200 text-center mx-auto text-sm uppercase tracking-wider font-medium"
      >
        Includes
      </h2>

      <div
        class="flex flex-wrap border border-gray-400 p-6 pt-8 relative rounded"
        style="bottom: 0.75rem;"
      >
        <div class="w-full text-sm text-gray-800 flex">
          <ul class="flex-1 text-left">
            <li
              v-for="(feature, index) in plansFeatures['all-plans']"
              :key="index"
              class="mb-6 lg:last:mb-0"
            >
              <Icon
                width="24px"
                height="24px"
                view-box="0 0 24 24"
                class="mr-2"
              >
                <Check />
              </Icon>
              <span>{{ feature }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import plansFeatures from '@data/plansFeatures'
import Icon from '@components/Icons/Icon'
import Check from '@components/Icons/Check'

export default {
  components: {
    Icon,
    Check
  },

  data() {
    return {
      plansFeatures
    }
  }

}
</script>
