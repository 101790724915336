/**
 * Validates talent has entered full name
 *
 * @param {any} value
 * @returns
 */
export const validateFullName = value => {
  if (value === 'undefined' || value === null || value === '') {
    return true
  }
  return value.includes(' ')
}
