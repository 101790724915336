<template>
  <div>
    <section class="text-center mx-auto">
      <h2 class="text-gray-600 tracking-wider uppercase font-bold mb-8">
        {{ headline }}
      </h2>
      <div
        class="flex flex-wrap lg:flex-row items-center justify-center lg:justify-between max-w-6xl text-center mx-auto -my-8"
      >
        <div class="py-8 px-4 text-center">
          <img
            class="w-16 lg:max-w-100"
            src="~@assets/images/intel.png"
            alt="Intel"
          />
        </div>

        <div class="py-8 px-4 text-center">
          <img
            class="w-16 lg:w-auto lg:max-w-100"
            src="~@assets/images/arcelormittal.png"
            alt="Arcelor Mittal"
          />
        </div>

        <div class="py-8 px-4  text-center">
          <img
            class="w-16 lg:w-auto lg:max-w-100"
            src="~@assets/images/airbus.png"
            alt="Airbus"
          />
        </div>

        <div class="py-8 px-4 text-center">
          <img
            class="w-16 lg:w-auto lg:max-w-100"
            src="~@assets/images/izettle.png"
            alt="iZettle"
          />
        </div>

        <!-- <div class="py-8 px-4 text-center">
          <img
            class="w-16 lg:w-auto lg:max-w-100"
            src="~@assets/images/vizor.png"
            alt="vizor"
          />
        </div> -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    headline: {
      type: String,
      default: ''
    }
  }
}
</script>
