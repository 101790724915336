<template>
  <div>
    <div class="bg-white p-6 rounded-tl-lg rounded-tr-lg sm:rounded-bl-lg sm:rounded-tr-none">
      <div v-if="!isAuthorised">
        <div v-if="validationLinkSent && !googleEmployer">
          <BaseCard class="text-center">
            <h1 class="text-3xl">
              Check your inbox
            </h1>
            <Mailbox class="w-56 text-center mx-auto my-6"/>
            <p class="text-lg">
              We’ve emailed a verification link to <span class="font-semibold text-xl">{{ formResponses.email }}</span>
            </p>
          </BaseCard>
        </div>
        <div v-else>
          <h1 class="text-2xl md:text-3xl xl:text-4xl flex items-start">
            <span>Get started <router-link
              :to="{ name: 'sign-in' }"
              class="block text-base"
              href="javascript:;"
            >
              or <span class="cta-link font-medium">sign in to your account</span></router-link>
            </span>
          </h1>

          <div class="mt-6 mb-4 max-w-xl">
            <SignInWithGoogle
              v-if="!googleEmployer"
              @success="signInWithGoogleSuccess"
              @error="signInWithGoogleError"
            />
            <p
              v-if="signInWithGoogleErrorMessage"
              class="text-red-500 text-xs text-left mt-2"
            >
              {{ signInWithGoogleErrorMessage }}
            </p>
            <ErrorsInline v-if="hasErrors">
              <span v-if="!$v.formResponses.email.email">Needs to be a valid email address</span>
            </ErrorsInline>
          </div>

          <form
            class="max-w-xl mt-6"
            novalidate
            @submit.prevent
          >
            <label
              v-if="!googleEmployer"
              class="block"
            >
              <span class="text-gray-800">Your name</span>
              <input
                v-model="$v.formResponses.fullName.$model"
                autocomplete="given-name"
                class="form-input mt-1 block w-full bg-gray-100"
              >
            </label>
            <ErrorsInline v-if="hasErrors">
              <span v-if="!$v.formResponses.fullName.required">This field is required</span>
              <span v-if="!$v.formResponses.fullName.validateFullName">You must use a first and second name</span>
            </ErrorsInline>

            <label
              v-if="!googleEmployer"
              class="block mt-6"
            >
              <span class="text-gray-800">Work email address</span>
              <input
                v-model="$v.formResponses.email.$model"
                type="email"
                class="form-input mt-1 block w-full bg-gray-100"
                autocomplete="email"
              >
            </label>
            <ErrorsInline v-if="hasErrors">
              <span v-if="!$v.formResponses.email.required">This field is required</span>
              <span v-if="!$v.formResponses.email.email">Needs to be a valid email address</span>
            </ErrorsInline>

            <label class="block mt-6">
              <span class="text-gray-800">Company name</span>
              <input
                v-model="$v.formResponses.companyName.$model"
                class="form-input mt-1 block w-full bg-gray-100"
                autocomplete="organization"
              >
            </label>
            <ErrorsInline v-if="hasErrors">
              <span v-if="!$v.formResponses.companyName.required">This field is required</span>
            </ErrorsInline>

            <section class="mt-6">
              <span class="flex">
                <label class="flex items-center">
                  <input
                    v-model="$v.formResponses.terms.$model"
                    type="checkbox"
                    class="form-checkbox"
                  >
                  <span class="ml-2 text-gray-800">
                    I agree to the <a
                      href="/terms/"
                      class="underline"
                      target="_blank"
                    >Terms &amp; Conditions</a>
                  </span>
                </label>
              </span>
              <ErrorsInline v-if="hasErrors">
                <span v-if="!$v.formResponses.terms.sameAs">This field is required</span>
              </ErrorsInline>
            </section>

            <section class="mt-8 text-right">
              <BaseButton
                :loading="loading"
                @click="submit"
              >
                Get Started
              </BaseButton>

              <div class="mt-2">
                <ErrorsInline
                  v-for="(error, index) in errors"
                  :key="index"
                >
                  <span
                    v-for="(message, index) in error"
                    :key="index"
                  >{{ message }}</span>
                </ErrorsInline>
              </div>
            </section>
          </form>
          <Logos
            headline="Join more than 600 businesses"
            class="max-w-xl text-gray-800 pt-6 mt-12 border-t"
          />
        </div>
      </div>

      <div v-if="isAuthorised && !hasActiveSubscription">
        <h1 class="text-2xl md:text-3xl xl:text-4xl">
          You haven’t selected a plan
        </h1>
        <p>
          <router-link
            :to="{ name: 'settings-plan' }"
            class="block text-base"
          >
            <span class="cta-link font-medium">Please choose a plan</span>
          </router-link>
        </p>
      </div>

      <div v-if="isAuthorised && hasActiveSubscription">
        <h1 class="text-2xl md:text-3xl xl:text-4xl">
          You’re signed up
        </h1>
        <p>
          <router-link
            :to="{ name: 'client-assessments-list' }"
            class="block text-base"
          >
            <span class="cta-link font-medium">Go to your assessments</span>
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import SignInWithGoogle from '@components/Auth/SignInWithGoogle'
import ErrorsInline from '@components/ErrorsInline'
import Logos from '@components/Logos'
import Mailbox from '@components/Vectors/Mailbox'

import { validationMixin } from 'vuelidate'
import { required, email, sameAs } from 'vuelidate/lib/validators'

import { validateFullName } from '@utils/nameValidation'
import { setLocalStorage } from '@utils/localStorage'

import { mapGetters } from 'vuex'

export default {
  components: {
    SignInWithGoogle,
    ErrorsInline,
    Logos,
    Mailbox
  },

  mixins: [validationMixin],

  props: {
    planPreference: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      satelliteName: process.env.VUE_APP_SATELLITE_NAME,
      loading: false,
      hasErrors: false,
      errors: [],
      formResponses: {
        fullName: null,
        email: null,
        companyName: null,
        terms: false
      },
      googleEmployer: null,
      signInWithGoogleErrorMessage: null,
      validationLinkSent: false
    }
  },

  computed: {
    ...mapGetters({
      isAuthorised: 'employers/isAuthorised',
      hasActiveSubscription: 'subscriptions/hasActiveSubscription'
    }),

    /**
     * @return {Boolean}
     */
    isClean() {
      return !this.$v.formResponses.$anyDirty
    },

    /**
     * @return {Object}
     */
    formData() {
      if (this.googleEmployer) {
        return {
          fullName: this.googleEmployer.fullName,
          email: this.googleEmployer.email,
          terms: this.formResponses.terms,
          companyName: this.formResponses.companyName,
          googleSignUp: true
        }
      }
      return {
        fullName: this.formResponses.fullName,
        email: this.formResponses.email,
        terms: this.formResponses.terms,
        companyName: this.formResponses.companyName
      }
    }
  },

  validations() {
    if (this.googleEmployer) {
      return {
        formResponses: {
          companyName: {
            required
          },
          terms: {
            sameAs: sameAs(() => true)
          }
        }
      }
    }
    return {
      formResponses: {
        fullName: {
          required,
          validateFullName
        },
        email: {
          required,
          email
        },
        companyName: {
          required
        },
        terms: {
          sameAs: sameAs(() => true)
        }
      }
    }
  },

  created() {
    if (this.planPreference) {
      console.log('🐘 Set plan preference to', this.planPreference)
      setLocalStorage('tc_plan_preference', this.planPreference)
    }
  },

  methods: {
    /**
     * Create an employer and team member
     */
    submit() {
      if (this.loading) {
        return
      }
      this.loading = true
      this.errors = false

      this.$v.formResponses.$touch()
      this.hasErrors = this.$v.formResponses.$anyError
      if (this.hasErrors || this.isClean) {
        this.loading = false
        return
      }

      console.log('👋 Signing up employer')

      this.$store.dispatch('employers/emailValidationLink', this.formData)
        .then(() => {
          if (this.googleEmployer) {
            this.$router.push({ name: 'client-assessments-list' })
          }
          this.validationLinkSent = true
          this.loading = false

          if (this.$gtm) {
            this.$gtm.trackEvent({
              event: 'event',
              eventCategory: 'Employer',
              eventAction: 'Requested validation',
              eventLabel: 'Via email'
            })

            this.$gtm.trackEvent({
              event: 'ga4_sign_up',
              method: 'Employer'
            })

            this.$gtm.trackEvent({
              event: 'ga4_sign_up_free'
            })
          }
        })
        .catch(error => {
          this.loading = false
          if (error.response && error.response.data.errorMessage) {
            console.error(error)
            this.errors = error.response.data
            return
          }
          if (error.response && error.response.data.errors) {
            console.error(error)
            this.errors = error.response.data.errors
            return
          }
          throw error
        })
    },
    /**
     * Handle successful sign in
     *
     * @param {String}
     */
    signInWithGoogleSuccess(idToken) {
      if (this.loading) {
        return
      }
      this.loading = true

      this.$store.dispatch('employers/verifyGoogleEmail', idToken)
        .then(employer => {
          this.loading = false
          this.googleEmployer = employer

          if (this.$gtm) {
            this.$gtm.trackEvent({
              event: 'event',
              eventCategory: 'Employer',
              eventAction: 'Sign up',
              eventLabel: 'Via Google'
            })

            this.$gtm.trackEvent({
              event: 'ga4_sign_up',
              method: 'Employer'
            })
          }
        })
        .catch((error) => {
          this.loading = false
          if (!error.response) {
            this.signInWithGoogleErrorMessage = error.message
            return
          }
          if (error.response.data.errorMessage) {
            this.signInWithGoogleErrorMessage = error.response.data.errorMessage
          } else if (error.response.data.message) {
            this.signInWithGoogleErrorMessage = error.response.data.message
          } else {
            this.signInWithGoogleErrorMessage = error.response.data.errors.email[0]
          }
        })
    },
    /**
     * Handle error from Google Sign In
     *
     * @param {Object}
     */
    signInWithGoogleError(error) {
      if (error.error === 'popup_closed_by_user') {
        return
      }
      this.signInWithGoogleErrorMessage = error.error
    }
  }
}
</script>
