<template>
  <div class="relative flex-grow w-full max-w-screen-xl px-4 py-8 mx-auto sm:py-16 sm:flex">
    <SignUp
      class="bg-white shadow-lg sm:w-2/3"
      :plan-preference="planPreference"
    />
    <div class="flex flex-col items-center justify-center p-4 text-center text-gray-800 rounded-tr-lg rounded-bl-lg rounded-br-lg bg-tertiary-200 bg-honeycomb sm:w-1/3 sm:rounded-br-none">
      <div v-if="testimonial">
        <p class="text-lg font-medium">
          “{{ testimonial.quote }}”
        </p>
        <div class="inline-flex flex-shrink-0 mt-6 border-2 border-white rounded-full">
          <img
            :src="testimonial.image.url"
            :alt="testimonial.byline"
            class="w-16 h-16 rounded-full"
          >
        </div>
        <p class="text-sm">
          {{ testimonial.byline }}
        </p>
      </div>
      <SignUpFeatureList class="mt-12" />
    </div>
  </div>
</template>

<script>
import SignUp from '@components/Auth/SignUp'
import SignUpFeatureList from '@components/Auth/SignUpFeatureList'

import { randomTestimonials } from '@utils/randomTestimonials'

export default {
  components: {
    SignUp,
    SignUpFeatureList
  },

  page() {
    return {
      title: 'Sign Up',
      meta: [
        { name: 'description', content: `Create a ${process.env.VUE_APP_SATELLITE_NAME} account` },
        { rel: 'canonical', href: `${process.env.VUE_APP_SPLASH_URL}${this.$route.fullPath}` },

        { property: 'og:title', content: 'Sign Up' },
        { property: 'og:site_name', content: process.env.VUE_APP_SATELLITE_NAME },
        { property: 'og:url', content: `${process.env.VUE_APP_SPLASH_URL}${this.$route.fullPath}` },
        { property: 'og:image', content: `${process.env.VUE_APP_SPLASH_URL}/img/tc-logo-white.png` },
        { property: 'og:type', content: 'website' },
        { property: 'og:description', content: `Create a ${process.env.VUE_APP_SATELLITE_NAME} account` },

        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:title', content: 'Sign Up' },
        { name: 'twitter:description', content: `Create a ${process.env.VUE_APP_SATELLITE_NAME} account` },
        { name: 'robots', content: 'noindex' }
      ]
    }
  },

  data() {
    return {
      randomTestimonials
    }
  },

  computed: {
    /**
     * @return {String}
     */
    planPreference() {
      return this.$route.query.plan
    },

    testimonial() {
      if (!this.randomTestimonials) {
        return null
      }
      return this.randomTestimonials[0]
    },

    /**
     * @return {Boolean}
     */
    usesDisableNewSubscriptions() {
      return this.$usesFeature('disable-new-subscriptions')
    }
  },

  created() {
    if (this.usesDisableNewSubscriptions) {
      // This is hardcoded as it’s only a fallback for reaching this page
      // which _shouldn’t happen_
      window.location.href = 'https://app.picked.ai/sign-up'
    }

    this.randomTestimonials = randomTestimonials()
  }
}
</script>
