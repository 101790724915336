import testimonialsData from '@data/cms/en/testimonials.json'

export const randomTestimonials = (count) => {
  const testimonials = testimonialsData
  if (typeof count === 'undefined') {
    count = 1
  }

  if (count > testimonials.length) {
    console.error('Cannot ask for more testimonials than there are')
    return
  }

  let randomIndexes = []

  for (let i = 0; i < count; i++) {
    let randomIndex
    let escapeCount = 0
    while (!Number.isInteger(randomIndex) || (Number.isInteger(randomIndex) && randomIndexes.includes(randomIndex))) {
      if (escapeCount > 100) {
        console.error('Cannot find enough testimonials')
        return
      }
      randomIndex = Math.floor(Math.random() * testimonials.length)
      escapeCount++
    }
    randomIndexes.push(randomIndex)
  }

  return randomIndexes.map(index => {
    return testimonials[index]
  })
}
